import React from 'react';
import styles from './Quote.module.scss';

interface QuoteProps {
  quote: React.ReactChild;
  iconUrl: string;
  iconAltText: string;
  author: string;
}

export default function Quote({ quote, iconUrl, iconAltText, author }: QuoteProps) {
  return (
    <div className={styles.quoteContainer}>
      <div className={styles.column}>
        <div className={styles.quote}>{quote}</div>
        <div className={styles.row}>
          <img src={iconUrl} alt={iconAltText} className={styles.vetIcon} />
          <div className={styles.author}>{author}</div>
        </div>
      </div>
    </div>
  );
}
