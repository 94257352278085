import React, { useEffect, useState } from 'react';

import * as events from '../../../../../lib/analytics/events';
import { PillOptions } from './SubscribeAndSave';
import styles from '../../../../../components/modals/modals.module.scss';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import Button from '../../../../../components/Button';
import ErrorMessage from '../../../../../components/ErrorMessage';

interface IPurchaseConfirmationModalProps {
  onContinue: () => void;
  trigger: React.ReactElement;
  purchaseSelected: PillOptions;
  buttonCopy: string;
  error?: string;
}

export default function PurchaseConfirmationModal({
  onContinue,
  trigger,
  purchaseSelected,
  buttonCopy,
  error,
}: IPurchaseConfirmationModalProps) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    if (error) {
      setButtonDisabled(false);
    }
  }, [error, setButtonDisabled]);
  const onAccept = () => {
    setButtonDisabled(true);
    events.supplements.subscribeAndSave.purchaseConfirmationModalContinue(buttonCopy);
    onContinue();
  };
  const onDecline = () => {
    events.supplements.subscribeAndSave.purchaseConfirmationModalGoBack(buttonCopy);
  };

  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => events.supplements.subscribeAndSave.purchaseButtonClicked(buttonCopy)}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            <div className={styles.header}>
              <h3>{purchaseSelected === PillOptions.oneBag ? 'Confirm order' : 'Confirm subscription'}</h3>
            </div>
            <p>
              {purchaseSelected === PillOptions.oneBag
                ? 'Please confirm your order of one bag of our new 8-in-1 Multivitamin.'
                : 'Please confirm the start of your Subscribe & Save for our new 8-in-1 Multivitamin. You can skip, change frequency, or cancel anytime.'}
            </p>
          </div>
          <div className={classNames(styles.actions, styles.wide, styles.shrink)}>
            <Button
              className={classNames(styles.button, { [styles.loading]: buttonDisabled })}
              onClick={() => {
                onAccept();
              }}
              disabled={buttonDisabled}
            >
              <span className={styles.content}>Confirm</span>
            </Button>
            <Button
              tertiary
              className={styles.button}
              onClick={() => {
                onDecline();
                close();
              }}
              disabled={buttonDisabled}
            >
              No, go back
            </Button>
          </div>
          {error && <ErrorMessage errors={[error]} />}
        </div>
      )}
    </Popup>
  );
}
