import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import styles from '../../../../ProductDetails/ProductDetails.module.scss';
import classNames from 'classnames';
import FAQ from '../ProductInfo/FAQ';
import { DesktopImageGallery } from '../../../../ManageSubscription/Supplements/components/ImageGallery';
import { CarouselController } from '../../../../../components/Carousel';
import { BENEFITS_VALUE_PROPS } from '../ProductInfo/ValueProps';
import ValuePropsBox from '../../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import NavBar from '../../../../../components/AppBar/NavBar';
import { useError } from '../../../../../components/ErrorMessage';
import Chooser from '../../../../../components/Chooser';
import SubscribeAndSaveContent from './SubscribeAndSaveContent/SubscribeAndSaveContent';
import BuyOneBagContent from './BuyOneBagContent/BuyOneBagContent';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { userIdentityQuery } from '../../../../../graphql-operations';
import useProductSkuMap from '../../../../../hooks/useProductSkuMap';
import CheckoutContext from '../../../../../lib/CheckoutContext';
import * as types from '../../../../../types';
import {
  getSmallestCollarOrdered,
  getMatchingSupplementForDogSize,
  sizeSortOrder,
  createSupplementCartItem,
} from '../helpers';
import { getLoadingOrErrorElement } from '../../../../../lib/util';
import { BUY_ONE_BAG_DETAILS, SUBSCRIBE_AND_SAVE_DETAILS } from './ShippingDetails';
import Quote from './Quote/Quote';
import SubscribeAndSaveButtons from './SubscribeAndSaveButtons/SubscribeAndSaveButtons';
import { useHistory } from 'react-router-dom';
import useCheckoutPaths from '../../../../../hooks/useCheckoutPaths';
import { getCustomerMessageFromApolloError, logInternalError } from '../../../../../lib/errors';
import { priceCartForCheckout } from '../../../../../lib/pricing';
import SupplementsLocationState from '../../../../../models/SupplementsLocationState';
import { cartActions } from '../../../../../reducers/storeCart';
import { supplements as events } from '../../../../../lib/analytics/events';
import subscribeAndSaveStyles from './SubscribeAndSave.module.scss';

export enum PillOptions {
  subscribeAndSave = 'SUBSCRIBE_AND_SAVE',
  oneBag = 'ONE_BAG',
}

export interface SubscribeAndSaveProps {
  originalCart?: types.Cart;
  originalCartPricing?: types.CartPricing;
  originalOrderId?: string;
  goToSupplementsMain?: () => void;
}

export default function SubscribeAndSave({
  originalCart,
  originalCartPricing,
  originalOrderId,
}: SubscribeAndSaveProps) {
  const desktopCarouselControllerRef = useRef<CarouselController>();
  const { cart, executePurchaseMutation, purchaseMutationLoading } = useContext(CheckoutContext);
  const productSkuMap = useProductSkuMap();
  const allProducts = useSelector((state: types.AppState) => state.config.products);
  const history = useHistory<SupplementsLocationState>();
  const checkoutPaths = useCheckoutPaths();
  const { checkoutState } = useContext(CheckoutContext);
  const session = useSelector((state: types.AppState) => state.session);
  const email = session?.email;
  const { error, setError } = useError();
  const [selectedOption, setSelectedOption] = useState<PillOptions>(PillOptions.subscribeAndSave);
  const [purchaseButtonClicked, setPurchaseButtonClicked] = useState(false);
  const [noThanksButtonClicked, setNoThanksButtonClicked] = useState(false);

  const singleBagProduct = useMemo((): types.ISupplementProduct => {
    return allProducts.find(
      (p) => p.category === types.ProductCategory.SUPPLEMENT && p.weeksBetweenShipments === 0,
    ) as types.ISupplementProduct;
  }, [allProducts]);

  const supplementSubscriptionOptions = useMemo((): types.ISupplementSubscriptionProduct[] => {
    const unsortedSupplements = allProducts.filter(
      (p) => p.category === types.ProductCategory.SUPPLEMENT && p.display,
    ) as types.ISupplementSubscriptionProduct[];
    // Sort items so they appear in product selector in a logical order.
    return unsortedSupplements.sort(
      (a, b) => sizeSortOrder(a.recommendedForDogSize) - sizeSortOrder(b.recommendedForDogSize),
    );
  }, [allProducts]);

  // Provide option to purchase the supplement subscription that is in accordance with the smallest collar
  // the user just purchased.
  const smallestSizePurchased = getSmallestCollarOrdered(cart, productSkuMap);
  const matchingSupplement = smallestSizePurchased
    ? getMatchingSupplementForDogSize(supplementSubscriptionOptions, smallestSizePurchased)
    : undefined;
  const defaultSelectedProduct = matchingSupplement ? matchingSupplement : supplementSubscriptionOptions[0];
  const productToPurchase = selectedOption === PillOptions.oneBag ? singleBagProduct : defaultSelectedProduct;

  const goToThankYouPage = useCallback(
    (orderCart: types.Cart, invoiceNumber: string, cartPricing: types.CartPricing) => {
      history.replace(checkoutPaths.ThankYou, {
        orderId: invoiceNumber,
        orderedCart: orderCart,
        orderedCartPricing: cartPricing,
      });
    },
    [checkoutPaths.ThankYou, history],
  );

  // Complete purchase of the selected supplement subscription
  const purchaseSupplement = useCallback(
    async (emailAddress: string | undefined) => {
      cartActions.addSingleLineCartItem({
        sku: productToPurchase.sku,
        quantity: 1,
      });
      const singleLineCartItem = createSupplementCartItem(productToPurchase);

      // Purchase the cart with only the supplement subscription line item
      try {
        const supplementPurchaseCart = {
          ...cart,
          couponCode: undefined, // Coupon codes do not apply to supplements, forces any applied via URL to be removed
          cartItems: { [singleLineCartItem.cartItemId]: singleLineCartItem },
        };
        const cartPricing = await priceCartForCheckout(supplementPurchaseCart, checkoutState);
        const result = await executePurchaseMutation(supplementPurchaseCart, checkoutState);

        const { chargeInvoice, giftCardInvoices } = result;
        const invoiceNumber = chargeInvoice?.number ?? giftCardInvoices?.[0]?.number;

        events.subscribeAndSave.purchaseSucceeded();
        goToThankYouPage(cart, invoiceNumber, cartPricing);
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        setPurchaseButtonClicked(false);
        setNoThanksButtonClicked(false);
        if (customerMessage) {
          setError(customerMessage);
        } else {
          logInternalError(err);
          setError(err);
        }
      }
    },
    [cart, checkoutState, setError, executePurchaseMutation, goToThankYouPage, productToPurchase],
  );

  const {
    data,
    loading: userIdentityLoading,
    error: userIdentityError,
  } = useQuery<types.gqlTypes.userIdentity>(userIdentityQuery, {});
  const loadingOrErrorElement = getLoadingOrErrorElement(userIdentityLoading, userIdentityError);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }
  const hasPurchasedSuppsSubs = data?.currentUser.hasPurchasedSupplementSubscription ?? false;
  const getsFreeTrial = !hasPurchasedSuppsSubs;

  const ctaText = () => {
    if (selectedOption === PillOptions.subscribeAndSave && getsFreeTrial) {
      return 'Try it free';
    }

    if (selectedOption === PillOptions.subscribeAndSave) {
      return 'Subscribe & Save';
    }
    if (selectedOption === PillOptions.oneBag) {
      return 'Place order';
    }

    // Default CTA
    return 'Place order';
  };

  return (
    <>
      <div className={classNames(styles.productContainer, styles.ecomFlowRevamp, 'ecom-flow-revamp')}>
        <div className={styles.galleryContainer}>
          <DesktopImageGallery carouselControllerRef={desktopCarouselControllerRef}>
            <NavBar postCheckout={true} />
          </DesktopImageGallery>
        </div>
        <div className={classNames(styles.productDetails, 'product-details')}>
          <div className={styles.productTitleContainer}>
            <div className={styles.productTitle}>Fi Supplements</div>
            <div className={styles.productTitleDescription}>
              You can now easily add our new 8-in-1 supplements to your Fi Membership.
            </div>
            <br />
            <Chooser
              autoHeight
              onSelect={(option: PillOptions) => {
                setSelectedOption(option);
                option === PillOptions.oneBag
                  ? events.subscribeAndSave.buyOneBagSelected()
                  : events.subscribeAndSave.subscribeAndSaveSelected();
              }}
              selectedOption={selectedOption}
              groups={[
                {
                  options: [
                    {
                      content: (
                        <SubscribeAndSaveContent
                          subscriptionOption={defaultSelectedProduct}
                          freeTrial={getsFreeTrial}
                        />
                      ),
                      value: PillOptions.subscribeAndSave,
                    },
                    {
                      content: <BuyOneBagContent product={singleBagProduct} />,
                      value: PillOptions.oneBag,
                    },
                  ],
                },
              ]}
            />
            <br />
            {selectedOption === PillOptions.subscribeAndSave && (
              <ValuePropsBox valueProps={SUBSCRIBE_AND_SAVE_DETAILS(defaultSelectedProduct)} />
            )}
            {selectedOption === PillOptions.oneBag && <ValuePropsBox valueProps={BUY_ONE_BAG_DETAILS} />}
            <div className={subscribeAndSaveStyles.sectionDivider} />
            <h2>Whole body benefits in one scoop</h2>
            <p>
              Even with the best diet, some nutrients can be hard to get. That’s why Fi’s 8-in-1 Multivitamin supplement
              delivers optimal amounts of nutrients in forms that help your dog thrive.
            </p>
            <ValuePropsBox valueProps={BENEFITS_VALUE_PROPS} />
            <div className={subscribeAndSaveStyles.sectionDivider} />
            <h2>Recommended by top veterinarians</h2>
            <Quote
              iconUrl="/product_images/supplements/subscribe-and-save/vet.png"
              iconAltText="Vet"
              quote={
                <div>
                  "If you want to keep your dog in their best shape for a{' '}
                  <span className={subscribeAndSaveStyles.highlight}>happier, healthier life,</span> you should, as I do
                  for my dogs, make supplements a part of their daily routine."
                </div>
              }
              author="Dr. Jeff Werber&nbsp;&nbsp;|&nbsp;&nbsp;Licensed Veterinarian"
            />
            <br />
            <div className={classNames(subscribeAndSaveStyles.sectionDivider, styles.mobile)} />
          </div>
          <SubscribeAndSaveButtons
            onPurchase={() => {
              setPurchaseButtonClicked(true);
              purchaseSupplement(email);
            }}
            noThanksButtonDisabled={noThanksButtonClicked}
            purchaseButtonDisabled={purchaseMutationLoading || purchaseButtonClicked}
            onNoThanks={() => {
              setNoThanksButtonClicked(true);
              events.subscribeAndSave.noThanksClicked();
              goToThankYouPage(originalCart!, originalOrderId!, originalCartPricing!);
            }}
            purchaseText={ctaText()}
            action={selectedOption === PillOptions.oneBag ? 'ordering' : 'subscribing'}
            error={error}
            selectedOption={selectedOption}
          />
        </div>
      </div>
      <FAQ />
    </>
  );
}
