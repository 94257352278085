import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Button from '../../../../components/Button';
import { BillingSubscription } from '../SplashPage';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import classNames from 'classnames';
import { ShippingAddressDetails } from './ShippingForm';
import ConfirmAddressChangeModal from './ConfirmAddressChangeModal';
import shippingStyles from '../../../../styles/form.module.scss';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import AddressConfirmationModal from '../../../Checkout/pages/Shipping/AddressConfirmationModal';
import useAddressValidation from '../../../Checkout/pages/Shipping/useAddressValidation';
import { Address, AddressValidationStatus, ShippingInfo } from '../../../../types';
import CheckoutContext from '../../../../lib/CheckoutContext';

interface IUpdateShippingAddressModalProps {
  onUpdateThisSubscription: (shippingAddress: AddressFields) => void;
  onUpdateAllSubscriptions: (shippingAddress: AddressFields) => void;
  subscription: BillingSubscription;
  trigger: React.ReactElement;
  hasMultipleSubscriptions: boolean;
}

export interface AddressFields {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export default function UpdateShippingAddressModal({
  onUpdateThisSubscription,
  onUpdateAllSubscriptions,
  trigger,
  subscription,
  hasMultipleSubscriptions,
}: IUpdateShippingAddressModalProps) {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [updateAllClicked, setUpdateAllClicked] = useState(false);
  const [updateJustThisClicked, setUpdateJustThisClicked] = useState(false);
  const [confirmAddressChangeModalOpen, setConfirmAddressChangeModalOpen] = useState(false);
  const initialFormValues: AddressFields = useMemo(
    () => ({
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
    }),
    [],
  );

  const { checkoutState } = useContext(CheckoutContext);
  const addressValidationStatus = useMemo(
    () => checkoutState.addressValidationStatus,
    [checkoutState.addressValidationStatus],
  );

  const saveConfirmedShipping = useCallback(
    (shippingInfo: ShippingInfo) => {
      if (updateJustThisClicked) {
        onUpdateThisSubscription(shippingInfo.address as AddressFields);
      } else if (updateAllClicked) {
        onUpdateAllSubscriptions(shippingInfo.address as AddressFields);
      }
    },
    [updateJustThisClicked, updateAllClicked, onUpdateThisSubscription, onUpdateAllSubscriptions],
  );

  const {
    executeAddressValidation,
    pendingAddressUserConfirmation,
    setPendingAddressUserConfirmation,
    unconfirmedShipping,
  } = useAddressValidation({
    addressValidationStatus,
    saveConfirmedShipping,
  });

  const [formValues, setFormValues] = useState<AddressFields>(initialFormValues);

  const valueIsValid = ([key, value]: string[]) => {
    if (key === 'line2') {
      return true;
    }

    return value !== '';
  };

  const validateFormValues = useCallback(() => {
    const valuesAreValid = Object.entries(formValues).every(valueIsValid);
    setButtonDisabled(!valuesAreValid);
  }, [formValues]);

  // Update form when shippingAddress changes.
  useEffect(() => {
    setFormValues(initialFormValues);
  }, [initialFormValues]);

  const onChange = useCallback(
    (fieldName: string, value: string) => {
      setFormValues({
        ...formValues,
        [fieldName]: value,
      });
      validateFormValues();
    },
    [formValues, validateFormValues],
  );

  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => events.updateShipmentAddressTapped({ subscriptionId: subscription.id })}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            <div className={styles.header}>
              <h3>Update shipment address</h3>
              <CloseIcon onClick={close} />
            </div>
            <hr className={styles.divider} />
            <form className={shippingStyles.form} autoComplete="on">
              <ShippingAddressDetails onChange={onChange} />
            </form>
          </div>
          <div className={styles.actions}>
            {hasMultipleSubscriptions ? (
              <ConfirmAddressChangeModal
                open={!!confirmAddressChangeModalOpen}
                onUpdateAllSubscriptions={() => {
                  setUpdateAllClicked(true);
                  executeAddressValidation(
                    { customerName: { firstName: '', lastName: '' }, address: formValues },
                    AddressValidationStatus.NeedsVerifyWithPrompt,
                  );
                }}
                onUpdateThisSubscription={() => {
                  setUpdateJustThisClicked(true);
                  executeAddressValidation(
                    { customerName: { firstName: '', lastName: '' }, address: formValues },
                    AddressValidationStatus.NeedsVerifyWithPrompt,
                  );
                }}
                setButtonDisabled={setButtonDisabled}
                setConfirmAddressChangeModalOpen={setConfirmAddressChangeModalOpen}
                trigger={
                  <Button className={styles.button} disabled={buttonDisabled}>
                    Update address
                  </Button>
                }
                subscription={subscription}
                shippingAddress={formValues}
              />
            ) : (
              <Button
                className={styles.button}
                disabled={buttonDisabled}
                onClick={() => {
                  setButtonDisabled(true);
                  setUpdateJustThisClicked(true);
                  executeAddressValidation(
                    { customerName: { firstName: '', lastName: '' }, address: formValues },
                    AddressValidationStatus.NeedsVerifyWithPrompt,
                  );
                }}
              >
                Update address
              </Button>
            )}
          </div>
          {unconfirmedShipping && (
            <AddressConfirmationModal
              open={!!pendingAddressUserConfirmation}
              inputAddress={unconfirmedShipping?.address ?? null}
              matchedAddress={pendingAddressUserConfirmation?.matchedAddress ?? null}
              status={pendingAddressUserConfirmation?.status ?? null}
              onCancel={() => {
                setPendingAddressUserConfirmation(null);
                setConfirmAddressChangeModalOpen(false);
              }}
              onSubmit={(submittedAddress: Address) => {
                setPendingAddressUserConfirmation(null);
                saveConfirmedShipping({
                  customerName: { firstName: '', lastName: '' },
                  address: submittedAddress,
                });
              }}
            />
          )}
        </div>
      )}
    </Popup>
  );
}
