import React, { useMemo, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { centsToDollarsNumber, getPriceForRateUnit } from '../../lib/util';
import * as types from '../../types';
import Button from '../Button';
import styles from './SubscriptionUpsellModal.module.scss';
import { Image } from '../Image';
import * as events from '../../lib/analytics/events';
import { useDispatch, useSelector } from 'react-redux';
import { applyCouponToCart, isValidCodeResult } from '../../lib/promoCode';
import { cartActions as storeCartActions } from '../../reducers/storeCart';

import Check from '../../assets/images/icons/check_green.svg';
import CollarBlue1 from '../../assets/images/collar_hero_blue_1.png';
import CollarYellow1 from '../../assets/images/collar_hero_yellow_1.png';
import CollarGray1 from '../../assets/images/collar_hero_gray_1.png';
import CollarPink1 from '../../assets/images/collar_hero_pink_1.png';
import { getFiGrowthBook } from '../../lib/growthbook';
import classNames from 'classnames';

interface SubscriptionUpsellModalProps {
  onAccept: () => void;
  onClose: () => void;
  onIgnore: () => void;
  open: boolean;
  selectedSubscription: types.ISubscriptionProduct;
  subscriptionToUpsell: types.ISubscriptionProduct;
  title?: string;
  collarColor: types.ColorOption;
  showOriginalVariant?: boolean;
}

function subscriptionName(subscriptionProduct: types.ISubscriptionProduct) {
  if (subscriptionProduct.renewalMonths === 1) {
    return 'Monthly';
  }

  return `${subscriptionProduct.renewalMonths} Month`;
}

function subscriptionNameForCTA(subscriptionProduct: types.ISubscriptionProduct) {
  if (subscriptionProduct.renewalMonths === 1) {
    return 'Month to Month';
  }

  return `${subscriptionProduct.renewalMonths} Months`;
}

function HeaderForSubscription({ subscriptionProduct }: { subscriptionProduct: types.ISubscriptionProduct }) {
  const { unit, displayRate } = getPriceForRateUnit(subscriptionProduct, 'week');
  return (
    <>
      {subscriptionName(subscriptionProduct)} - {displayRate}/{unit}
    </>
  );
}

export default function SubscriptionUpsellModal({
  onAccept,
  onClose,
  onIgnore,
  open,
  selectedSubscription,
  subscriptionToUpsell,
  showOriginalVariant,
  title = 'Prepay Your Membership and Save 30%',
  collarColor = types.ColorOption.Blue,
}: SubscriptionUpsellModalProps) {
  const analyticsEventProperties: events.SubscriptionEventProps = useMemo(
    () => ({
      priceInCents: subscriptionToUpsell.priceInCents,
      productId: subscriptionToUpsell.id,
      productName: subscriptionToUpsell.name,
      sku: subscriptionToUpsell.sku,
    }),
    [subscriptionToUpsell],
  );

  const cart = useSelector((state: types.AppState) => state.storeShop.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      events.subscriptionUpsell.viewed(analyticsEventProperties);
    }
  }, [analyticsEventProperties, open]);

  // This is a rare situation where we want the default value to be true,
  // because we're experimenting with *removing* the waiver
  const waiveActivationFee =
    getFiGrowthBook().getFeatureValue<boolean>('ecom-3-month-upsell-waive-activation-fee', true) &&
    !showOriginalVariant;

  let imageUrl: string;
  if (collarColor === types.ColorOption.Gray) {
    imageUrl = CollarGray1;
  } else if (collarColor === types.ColorOption.Pink) {
    imageUrl = CollarPink1;
  } else if (collarColor === types.ColorOption.Yellow) {
    imageUrl = CollarYellow1;
  } else {
    imageUrl = CollarBlue1;
  }

  const secondaryButtonText = showOriginalVariant
    ? `Continue with ${subscriptionName(selectedSubscription)}`
    : `Keep ${subscriptionNameForCTA(selectedSubscription)}`;

  const upsellCostOverYearInCents =
    (subscriptionToUpsell.priceInCents / subscriptionToUpsell.renewalMonths) * 12 +
    (subscriptionToUpsell.activationFeeInCents ?? 0);
  const currentCostOverYearInCents =
    selectedSubscription.priceInCents * 12 + (selectedSubscription.activationFeeInCents ?? 0);
  const currentCostOverUpsellTermInCents =
    selectedSubscription.priceInCents * subscriptionToUpsell.renewalMonths +
    (selectedSubscription.activationFeeInCents ?? 0);

  const featuredPriceInCents = subscriptionToUpsell.priceInCents;
  const crossedOutPriceInCents = currentCostOverUpsellTermInCents;

  const yearlySavingsInCents = currentCostOverYearInCents - upsellCostOverYearInCents;

  if (showOriginalVariant) {
    title = `Prepay Your Membership and Save`;
  } else if (!waiveActivationFee) {
    title = `Prepay Your Membership and Save $${centsToDollarsNumber(yearlySavingsInCents)}/yr`;
  }

  return (
    <Popup className="s3-popup" onClose={onClose} open={open} closeOnDocumentClick={false}>
      <div
        className={classNames(styles.container, {
          [styles.originalVariant]: showOriginalVariant,
        })}
      >
        <div className={styles.headerSection}>
          <h1>{title}</h1>
          {showOriginalVariant && (
            <>
              <div className={classNames(styles.subscriptionHeader, styles.selectedSubscriptionHeader)}>
                <h2>
                  <HeaderForSubscription subscriptionProduct={selectedSubscription} />
                </h2>
              </div>
              <div className={classNames(styles.subscriptionHeader, styles.subscriptionToUpsellHeader)}>
                <h2>
                  <HeaderForSubscription subscriptionProduct={subscriptionToUpsell} />
                </h2>
                <div className={styles.upgradeValueProp}>Prioritized Shipping</div>
              </div>
            </>
          )}
          {!showOriginalVariant && (
            <div className={styles.offerContainer}>
              <div className={styles.specialOfferChipSmall}>
                <p>SPECIAL OFFER</p>
              </div>
              <div className={styles.offerDetailsContainer}>
                <p className={styles.upsellSubscriptionTermText}>{subscriptionName(subscriptionToUpsell)}</p>
                <div>
                  <div className={styles.pricesContainer}>
                    <p className={styles.upsellSubscriptionPriceText}>${centsToDollarsNumber(featuredPriceInCents)}</p>
                    {waiveActivationFee ? (
                      <p className={styles.selectedSubscriptionPriceText}>
                        ${centsToDollarsNumber(crossedOutPriceInCents)}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  {waiveActivationFee ? (
                    <p className={styles.upgradeIncentive}>
                      <span>
                        ${centsToDollarsNumber(selectedSubscription.activationFeeInCents ?? 0)} activation fee
                      </span>{' '}
                      waived
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Image image={{ type: 'image', url: Check }} />
            </div>
          )}
        </div>
        <div className={styles.heroImageSection}>
          <Image image={{ type: 'image', url: imageUrl }} />
        </div>
        <div className={styles.ctaSection}>
          <div className={styles.ctaButtons}>
            <Button
              className={styles.ctaPrimary}
              onClick={() => {
                events.subscriptionUpsell.acceptedUpsell(analyticsEventProperties);
                onAccept();

                if (waiveActivationFee) {
                  /**
                   * TODO: if/when we confirm we want to apply the discount code, this should be reworked
                   * and probably pulled up into the actual `onAccept` function itself.
                   * We may also be fully removing the activation fee from all 3 month subscriptions,
                   * in which case the coupon wouldn't be necessary anymore.
                   */
                  const couponCode = '3MODISCOUNT';
                  applyCouponToCart(cart, couponCode).then((couponResult) => {
                    if (isValidCodeResult(couponResult)) {
                      dispatch(storeCartActions.addCoupon(couponCode));
                    }
                  });
                }
              }}
            >
              {showOriginalVariant
                ? `Upgrade to ${subscriptionNameForCTA(subscriptionToUpsell)}`
                : `Switch to ${subscriptionNameForCTA(subscriptionToUpsell)}`}
            </Button>
            <Button
              onClick={() => {
                events.subscriptionUpsell.ignoredUpsell(analyticsEventProperties);
                onIgnore();
              }}
              secondary
            >
              {secondaryButtonText}
            </Button>
          </div>
        </div>
        {showOriginalVariant && (
          <div className={styles.disclaimerSection}>
            <div className={styles.disclaimer}>3 Month membership is billed as $54 every 3 months.</div>
            <div className={styles.disclaimer}>Monthly memberships are billed as $19 every month</div>
          </div>
        )}
      </div>
    </Popup>
  );
}
