import React, { useState } from 'react';

import Button from '../../../../../components/Button';
import ErrorMessage from '../../../../../components/ErrorMessage';
import * as events from '../../../../../lib/analytics/events';
import { ISupplementSubscriptionProduct, SupplementCheckoutFlow } from '../../../../../types';
import styles from '../ProductInfo/ProductInfo.module.scss';
import classNames from 'classnames';

interface SupplementPurchaseButtonsProps {
  selectedProduct: ISupplementSubscriptionProduct;
  handlePurchase: () => Promise<void> | void;
  handleNoThanks?: () => void;
  showNoThanks?: boolean;
  error?: string;
  errorId?: string;
  flow: SupplementCheckoutFlow;
  hideFree: boolean;
}

export default function SupplementPurchaseButtons({
  selectedProduct,
  handlePurchase,
  handleNoThanks,
  error,
  errorId,
  showNoThanks = true,
  flow,
  hideFree,
}: SupplementPurchaseButtonsProps) {
  const [purchaseButtonDisabled, setPurchaseButtonDisabled] = useState(false);

  let selectedDetails = [];
  if (hideFree) {
    selectedDetails = [
      `${selectedProduct.name}`,
      `Ships every ${selectedProduct.weeksBetweenShipments} weeks`,
      `${selectedProduct?.displayedRate}/${selectedProduct?.displayedRateUnit}`,
    ];
  } else {
    selectedDetails = [
      `${selectedProduct.name}`,
      `Ships every ${selectedProduct.weeksBetweenShipments} weeks`,
      'First bag FREE',
    ];
  }
  return (
    <>
      {' '}
      <div className={styles.getItButtonContainer}>
        <div className={classNames(styles.selectedDetails)}>
          {selectedDetails.map((detail, idx) => {
            return (
              <>
                <span>{detail}</span>
                <>{idx !== selectedDetails.length - 1 && String.fromCharCode(183)}</>
              </>
            );
          })}
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={(evt) => {
              evt.preventDefault();
              events.supplements.getItClicked(flow);
              setPurchaseButtonDisabled(true);
              handlePurchase();
            }}
            disabled={purchaseButtonDisabled}
          >
            {hideFree ? 'Subscribe' : 'Try it free'}
          </Button>
          {showNoThanks && (
            <Button
              className={styles.button}
              secondary={true}
              onClick={() => {
                events.supplements.noThanksClicked();
                handleNoThanks && handleNoThanks();
              }}
            >
              No, Thanks
            </Button>
          )}
        </div>
        <div className={styles.error}>{error && <ErrorMessage errors={[error]} errorID={errorId} />}</div>
      </div>
    </>
  );
}
