import classNames from 'classnames';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../../../components/AppBar/NavBar';
import { CarouselController } from '../../../components/Carousel';
import SubscriptionOptions from '../../../components/SubscriptionOptions';
import SubscriptionUpsellModal from '../../../components/SubscriptionUpsellModal';
import HideChatWidget from '../../../components/ZendeskChat/HideChatWidget';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import * as events from '../../../lib/analytics/events';
import { getFiGrowthBook } from '../../../lib/growthbook';
import { commonDetailsForSize } from '../../../lib/size';
import { commonDetailsForSubscription } from '../../../lib/subscription';
import * as types from '../../../types';
import { series3CollarId } from '../../../types';
import styles from '../ProductDetails.module.scss';
import BandVariantOptions from '../components/BandVariantOptions';
import SoldOutBanner from '../components/SoldOutBanner';
import useIsSkuBlocked from '../hooks/useIsSkuBlocked';
import useSubscriptionSelector from '../hooks/useSubscriptionSelector';
import useVariantSelector, { getNanoVariantOptions } from '../hooks/useVariantSelector';
import CollarAddToBagButton from './components/CollarAddToBagButton';
import FAQ from './components/FAQ';
import { DesktopImageGallery } from './components/ImageGallery';
import MembershipValueProps from './components/MembershipValueProps';
import ProductSummary from './components/ProductSummary';
import {
  getNanoVariantPetAgeMonths,
  getNanoVariantPetName,
  getNanoVariantPetGender,
  showNanoVariant,
} from '../../../components/NanoBanner/NanoBanner';
import NanoProductDetails from './components/NanoProductDetails/NanoProductDetails';

const PRODUCT_TITLE = 'SERIES 3';
const SUBSCRIPTION_TITLE = 'Pick your membership';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);
CustomEase.create('scrollTo', '0.65, 0, 0.35, 1');

interface OnChangeHandlerProps {
  desktopCarouselControllerRef: React.MutableRefObject<CarouselController | undefined>;
  priceInCents: number;
  productName: string;
  subscriptionPriceInCents?: number;
  currentCarouselImage: number;
  isUpgradePath: boolean;
}

function useOnChangeHandlers({
  desktopCarouselControllerRef,
  priceInCents,
  productName,
  subscriptionPriceInCents,
  currentCarouselImage,
  isUpgradePath,
}: OnChangeHandlerProps) {
  // For analytics events, since we're viewing an S3 collar + subscription membership bundle, we should add the
  // subscription price to the collar price
  const totalPriceInCents = useMemo(
    () => priceInCents + (subscriptionPriceInCents ?? 0),
    [priceInCents, subscriptionPriceInCents],
  );

  const onColorChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series3CollarColorSwitch({
        color: newVariant.options.color,
        priceInCents: totalPriceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
        isUpgrade: isUpgradePath,
      });

      // Reset the desktop carousel to the first image when the color changes
      if (currentCarouselImage !== 1) {
        desktopCarouselControllerRef.current?.select(0);
      }
    },
    [totalPriceInCents, productName, isUpgradePath, currentCarouselImage, desktopCarouselControllerRef],
  );

  const onSizeChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series3CollarSizeSwitch({
        color: newVariant.options.color,
        priceInCents: totalPriceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
        isUpgrade: isUpgradePath,
      });
    },
    [isUpgradePath, productName, totalPriceInCents],
  );

  const onSelectedVariantChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series3CollarVariantSwitch({
        color: newVariant.options.color,
        priceInCents: totalPriceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
        isUpgrade: isUpgradePath,
      });
    },
    [isUpgradePath, productName, totalPriceInCents],
  );

  return {
    onColorChange,
    onSizeChange,
    onSelectedVariantChange,
  };
}

const ctaButtonText = (hasUpdatedSize: boolean, hasUpdatedSubscriptionSku: boolean): string => {
  const nanoVariantEnabled = showNanoVariant();
  const petName = getNanoVariantPetName();

  if (!hasUpdatedSize) {
    return nanoVariantEnabled ? `Pick a size` : 'Pick Your Size';
  }

  if (!hasUpdatedSubscriptionSku) {
    return 'Pick Your Membership';
  }

  return nanoVariantEnabled ? `Purchase for ${petName}` : 'Add To Cart';
};

const ctaDescriptionText = (
  selectedVariant: types.IVariant,
  selectedSubscription: types.ISubscriptionProduct | undefined,
): string => {
  const toTitleCase = (str: string): string => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  let description =
    toTitleCase(selectedVariant.options.color) + ' · ' + commonDetailsForSize(selectedVariant.options.size).sizeName;
  if (selectedSubscription) {
    description += ' · ' + commonDetailsForSubscription(selectedSubscription).subscriptionName;
  }
  return description;
};

interface Series3CollarDetailsProps {
  addBundleCartItem: (variantSku: string, subscriptionSku: string) => void;
  availableSubscriptions?: types.ISubscriptionProduct[];
  hideSubscriptionOptions?: boolean;
  productName: string;
  preselectedSubscriptionSku?: string;
  priceInCents: number;
  variants: types.IVariant[];
  isUpgradePath?: boolean;
}

export default function Series3CollarDetails({
  addBundleCartItem,
  availableSubscriptions,
  hideSubscriptionOptions,
  productName,
  preselectedSubscriptionSku,
  priceInCents,
  variants,
  isUpgradePath = false,
}: Series3CollarDetailsProps) {
  const desktopCarouselControllerRef = useRef<CarouselController>();
  const referralCode = useSelector((state: types.AppState) => state.storeShop.cart.referralCode);
  const nanoVariantEnabled = showNanoVariant();
  const petName = getNanoVariantPetName();
  const petPronoun = getNanoVariantPetGender() === 'male' ? 'his' : 'her';
  const nanoSizeSuggestionEnabled =
    nanoVariantEnabled && getFiGrowthBook().getFeatureValue('nano-registration-size-suggestion-experiment', false);
  const nanoSizeSuggestion = nanoSizeSuggestionEnabled ? getNanoVariantOptions().size : null;
  preselectedSubscriptionSku = nanoSizeSuggestionEnabled ? 'sub-monthly-6m-006' : preselectedSubscriptionSku;

  const sixMonthPreselectionExperimentEnabled = getFiGrowthBook().getFeatureValue(
    'pdp-six-month-preselection-experiment',
    false,
  );
  if (sixMonthPreselectionExperimentEnabled) {
    preselectedSubscriptionSku = 'sub-monthly-6m-006';
  }

  const showOriginalUpsellModalVariant = getFiGrowthBook().getFeatureValue<boolean>(
    'ecom-3-month-upsell-original-variant',
    false,
  );

  // A/B test for a design that more prominently displays the total amount due for the subscription term
  const prominentPricingDesign =
    isUpgradePath || nanoVariantEnabled
      ? false
      : getFiGrowthBook().getFeatureValue<boolean>('ecom-prominent-pricing', false);

  // Can be used to display a coupon-based pill above the prepaid plans
  const couponCodePillDisplay = useMemo((): { shouldShow: boolean; title?: string; subtitle?: string } => {
    return {
      shouldShow: false,
    };
  }, []);

  const allSeries3Subscriptions = useSubscriptionProducts(series3CollarId);
  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);
  const [currentCarouselImage, setCurrentCarouselImage] = useState<number>(0);

  const subscriptionProducts: types.ISubscriptionProduct[] = useMemo(
    () => availableSubscriptions ?? allSeries3Subscriptions,
    [allSeries3Subscriptions, availableSubscriptions],
  );

  const { selectedSubscription, selectSubscriptionSku, hasUpdatedSubscriptionSku } = useSubscriptionSelector(
    subscriptionProducts,
    preselectedSubscriptionSku,
    sixMonthPreselectionExperimentEnabled,
  );

  const shouldShowSubscriptionOptions = useMemo(() => {
    if (!selectedSubscription) {
      return true;
    }

    return !hideSubscriptionOptions;
  }, [selectedSubscription, hideSubscriptionOptions]);

  // A subscription product that we should try to upsell to the user
  const subscriptionToUpsell: types.ISubscriptionProduct | undefined = useMemo(() => {
    if (!selectedSubscription) {
      return undefined;
    }

    const planCodeToUpsell = selectedSubscription.attemptUpsellToPlanCode;
    if (!planCodeToUpsell) {
      return undefined;
    }

    return subscriptionProducts.find((p) => p.sku === planCodeToUpsell);
  }, [selectedSubscription, subscriptionProducts]);

  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    desktopCarouselControllerRef,
    priceInCents,
    productName,
    subscriptionPriceInCents: selectedSubscription?.priceInCents,
    currentCarouselImage,
    isUpgradePath,
  });

  const { changeVariantOptions, selectedVariant, hasUpdatedSize } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants,
  });
  const isBlocked = useIsSkuBlocked(selectedVariant.sku);
  const sizePickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const subscriptionPickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  // Analytics - S3 collar page viewed
  useEffect(() => {
    events.series3CollarViewed();
  }, []);

  // Add to bag logic
  const addToBag = useCallback(
    (variant: types.IVariant, subscriptionProduct: types.ISubscriptionProduct) => {
      events.series3CollarAddedToBag({
        color: variant.options.color,
        priceInCents,
        productName,
        size: variant.options.size,
        sku: variant.sku,
        isUpgrade: isUpgradePath,
      });
      events.series3CollarActionButtonClick({
        color: variant.options.color,
        priceInCents,
        productName,
        size: variant.options.size,
        sku: variant.sku,
        isUpgrade: isUpgradePath,
      });

      events.series3SubscriptionAddedToBag({
        priceInCents: subscriptionProduct.priceInCents,
        productId: subscriptionProduct.id,
        productName: subscriptionProduct.name,
        sku: subscriptionProduct.sku,
        isUpgrade: isUpgradePath,
      });

      addBundleCartItem(variant.sku, subscriptionProduct.sku);
    },
    [addBundleCartItem, isUpgradePath, priceInCents, productName],
  );

  setTimeout(() => {
    ScrollTrigger.create({
      trigger: '.subscription-options .selected-chooser-option',
      start: 'top center',
      onEnter: () => {
        if (sixMonthPreselectionExperimentEnabled && hasUpdatedSize && !hasUpdatedSubscriptionSku) {
          selectSubscriptionSku(selectedSubscription?.sku);
        }
      },
    });
  }, 100);

  const onAddToBagClicked = useCallback(() => {
    const offsetY = Array.from(document.querySelectorAll('.promotionBanner')).reduce(
      (total, el) => (total + (el as HTMLElement).style.zIndex === '-1' ? 0 : (el as HTMLElement).offsetHeight),
      0,
    );
    if (!hasUpdatedSize) {
      if (sizePickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: sizePickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
        });
      }
      return false;
    }

    if (isBlocked || !selectedSubscription || !hasUpdatedSubscriptionSku) {
      if (subscriptionPickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: subscriptionPickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
          onComplete: () => {
            if (sixMonthPreselectionExperimentEnabled && !hasUpdatedSubscriptionSku) {
              selectSubscriptionSku(selectedSubscription?.sku);
            }
          },
        });
      }
      return false;
    }

    if (subscriptionToUpsell) {
      setShowUpsellModal(true);
      return false;
    }

    addToBag(selectedVariant, selectedSubscription);
    return true;
  }, [
    isBlocked,
    selectedSubscription,
    subscriptionToUpsell,
    addToBag,
    selectedVariant,
    hasUpdatedSize,
    hasUpdatedSubscriptionSku,
    selectSubscriptionSku,
    sixMonthPreselectionExperimentEnabled,
  ]);

  return (
    <>
      {selectedSubscription && subscriptionToUpsell && (
        <SubscriptionUpsellModal
          onAccept={() => addToBag(selectedVariant, subscriptionToUpsell)}
          onClose={() => setShowUpsellModal(false)}
          onIgnore={() => addToBag(selectedVariant, selectedSubscription)}
          open={showUpsellModal}
          selectedSubscription={selectedSubscription}
          subscriptionToUpsell={subscriptionToUpsell}
          collarColor={selectedVariant.options.color}
          showOriginalVariant={showOriginalUpsellModalVariant}
        />
      )}
      <div
        className={classNames(styles.productContainer, styles.ecomFlowRevamp, 'ecom-flow-revamp', {
          'ecom-nano-variant': nanoVariantEnabled,
          'ecom-flow-revamp-light': nanoVariantEnabled
            ? selectedVariant.options.color === types.ColorOption.Blue ||
              selectedVariant.options.color === types.ColorOption.Pink ||
              currentCarouselImage === 3
            : currentCarouselImage === 3 || currentCarouselImage === 5,
        })}
      >
        <div className={classNames(styles.galleryContainer, { [styles.nanoGalleryContainer]: nanoVariantEnabled })}>
          <DesktopImageGallery
            carouselControllerRef={desktopCarouselControllerRef}
            selectedVariant={selectedVariant}
            onChange={(idx) => setCurrentCarouselImage(idx)}
          >
            {!isUpgradePath && <NavBar />}
          </DesktopImageGallery>
        </div>
        <div className={classNames(styles.productDetails, 'product-details')}>
          <div className={styles.productTitleContainer}>
            {nanoVariantEnabled && (
              <>
                <div className={styles.nanoProductTitle}>Fi Smart Collar</div>
                <div className={styles.nanoProductDescription}>
                  Microchips are a great start, but they can’t tell you where your dog is. Get the Fi Collar for 24/7
                  live location access.
                </div>
              </>
            )}
            {!nanoVariantEnabled && <div className={styles.productTitle}>Fi Series 3</div>}
            {!nanoVariantEnabled && (
              <div className={styles.productTitleDescription}>Pick your color to get started</div>
            )}
            {nanoVariantEnabled && (
              <div className={styles.nanoProductTitleDescription}>{`Choose a color for ${petName}`}</div>
            )}
          </div>
          <BandVariantOptions
            bandSeries={types.BandSeries.F3}
            changeVariantOptions={changeVariantOptions}
            selectedVariant={selectedVariant}
            variants={variants}
            sizePickerRef={sizePickerRef}
            startSizeAsUnselected={!hasUpdatedSize}
            hideMakersLink={true}
            showColorTitle={false}
            selectedColorLabelPosition="bottom"
            sizeTitle={
              nanoSizeSuggestion
                ? getNanoVariantPetAgeMonths() <= 6
                  ? `We recommend a ${nanoSizeSuggestion.toString()} collar for your puppy`
                  : `We recommend a ${nanoSizeSuggestion.toString()} collar`
                : nanoVariantEnabled
                ? `Pick ${petPronoun} size`
                : 'Pick your size'
            }
          />

          {isBlocked && <SoldOutBanner />}

          <HideChatWidget />

          {shouldShowSubscriptionOptions && (
            <SubscriptionOptions
              allowNoSubscription={false}
              onSubscriptionChange={selectSubscriptionSku}
              selectedSubscriptionSku={selectedSubscription?.sku}
              subscriptionProducts={subscriptionProducts}
              title={nanoVariantEnabled ? 'Pick the membership that works best for you' : SUBSCRIPTION_TITLE}
              showReferralPill={!!referralCode}
              couponPillDisplay={couponCodePillDisplay}
              productName={PRODUCT_TITLE}
              subscriptionPickerRef={subscriptionPickerRef}
              startAsUnselected={!nanoSizeSuggestionEnabled && !sixMonthPreselectionExperimentEnabled}
              disableSection={!hasUpdatedSize}
              prominentPricingDesign={prominentPricingDesign}
            />
          )}

          <MembershipValueProps />
          {!showUpsellModal && (
            <CollarAddToBagButton
              disabled={isBlocked || !selectedSubscription}
              onAddToBag={onAddToBagClicked}
              ctaText={ctaButtonText(hasUpdatedSize, hasUpdatedSubscriptionSku)}
              fadeInRef={!hasUpdatedSize ? sizePickerRef : undefined}
              isVisibleOverride={hasUpdatedSize}
              descriptionText={
                hasUpdatedSize && hasUpdatedSubscriptionSku
                  ? ctaDescriptionText(selectedVariant, selectedSubscription)
                  : null
              }
            />
          )}
        </div>
      </div>
      {nanoVariantEnabled && <NanoProductDetails />}
      <ProductSummary />
      <FAQ />
    </>
  );
}
