import React, { useState } from 'react';

import Button from '../../../../components/Button';
import { ISupplementSubscriptionProduct } from '../../../../types';
import SupplementPill from './SupplementPill';
import { BillingSubscription, SubscriptionOption } from '../SplashPage';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import classNames from 'classnames';
import { supplementsManagement as events } from '../../../../lib/analytics/events';

interface IUpdateCadenceModalProps {
  onContinue: (sku: string) => void;
  onCancel?: () => void;
  subscription: BillingSubscription;
  currentSubscriptionOption: SubscriptionOption;
  subscriptionOptions: ISupplementSubscriptionProduct[];
  trigger: React.ReactElement;
  showCancel?: boolean;
  open?: boolean;
}

export default function UpdateCadenceModal({
  onContinue,
  onCancel,
  trigger,
  subscription,
  currentSubscriptionOption,
  subscriptionOptions,
  showCancel = true,
  open,
}: IUpdateCadenceModalProps) {
  const currentSku = currentSubscriptionOption.sku;
  const currentSkuNoTrial = currentSku.endsWith('-no-trial')
    ? currentSku.substring(0, currentSku.indexOf('-no-trial'))
    : currentSku;
  const [selectedOption, setSelectedOption] = useState(currentSkuNoTrial);
  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      open={open}
      onOpen={() => events.shipmentFrequencyTapped({ subscriptionId: subscription.id })}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            {
              <>
                <div className={styles.header}>
                  <h3>Shipment frequency</h3>
                  <CloseIcon
                    onClick={() => {
                      events.modalCloseEvent({}, 'Update Cadence');
                      close();
                    }}
                  />
                </div>
                <hr className={styles.divider} />
                <br />
                {subscriptionOptions.map((subOption: any) => (
                  <SupplementPill
                    subscription={subscription}
                    subscriptionDetails={subOption}
                    version={'selectable'}
                    handleClick={() => {
                      setSelectedOption(subOption.sku);
                      setTimeout(() => onContinue(subOption.sku), 500);
                    }}
                    selected={selectedOption === subOption.sku}
                    key={subOption.sku}
                  />
                ))}
              </>
            }
          </div>
          <div className={styles.actions}>
            {showCancel && (
              <Button
                className={styles.button}
                tertiary
                onClick={() => {
                  close();
                  onCancel && onCancel();
                }}
              >
                Cancel subscription
              </Button>
            )}
          </div>
        </div>
      )}
    </Popup>
  );
}
