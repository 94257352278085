import React from 'react';

import Button from '../../../../components/Button';
import { BillingSubscription } from '../SplashPage';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import classNames from 'classnames';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import { DateTime } from 'luxon';
import { centsToDollarsNumber } from '../../../../lib/util';

interface IClaimDiscountModalProps {
  onContinue: () => void;
  subscription: BillingSubscription;
  trigger: React.ReactElement;
}

export default function ClaimDiscountModal({ onContinue, trigger, subscription }: IClaimDiscountModalProps) {
  const pageName = 'Claim 33% Discount';

  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => console.log('TODO: analytics event')}
    >
      {(close: () => void) => (
        <div className={classNames(styles.container, styles.webview)}>
          <div className={classNames(styles.body, styles.shrink)}>
            {
              <>
                <div className={styles.header}>
                  <h3>Confirm shipment discount</h3>
                </div>
                <p>
                  Your next shipment on{' '}
                  {DateTime.fromISO(subscription.supplementShipmentDates!.nextShipmentDate).toFormat('cccc, LLLL d')}{' '}
                  will be discounted 33% and billed as $
                  {centsToDollarsNumber((subscription.subscriptionOption.priceInCents * 2) / 3)}.
                </p>
              </>
            }
          </div>
          <div className={classNames(styles.actions, styles.shrink)}>
            <Button className={styles.button} onClick={onContinue}>
              Confirm
            </Button>
            <Button
              className={styles.button}
              tertiary
              onClick={() => {
                events.modalCloseEvent({}, pageName);
                close();
              }}
            >
              Go back
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
}
