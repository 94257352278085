import React, { useCallback } from 'react';
import styles from './SubscribeAndSaveButtons.module.scss';
import classNames from 'classnames';
import Button from '../../../../../../components/Button';
import ErrorMessage from '../../../../../../components/ErrorMessage';
import PurchaseConfirmationModal from '../PurchaseConfirmationModal';
import { PillOptions } from '../SubscribeAndSave';

interface SubscribeAndSaveButtonsProps {
  purchaseButtonDisabled?: boolean;
  noThanksButtonDisabled?: boolean;
  onPurchase: () => void;
  onNoThanks: () => void;
  purchaseText: string;
  action: string;
  error?: string;
  selectedOption: PillOptions;
}

export default function SubscribeAndSaveButtons({
  purchaseButtonDisabled,
  noThanksButtonDisabled,
  onPurchase,
  onNoThanks,
  purchaseText,
  action,
  error,
  selectedOption,
}: SubscribeAndSaveButtonsProps) {
  const onPurchaseClicked = useCallback(() => {
    if (purchaseButtonDisabled) {
      return false;
    }

    return onPurchase();
  }, [purchaseButtonDisabled, onPurchase]);

  return (
    <div className={classNames(styles.productAction, styles.stickyCTA)}>
      <div className={classNames(styles.buttons)}>
        <PurchaseConfirmationModal
          purchaseSelected={selectedOption}
          onContinue={onPurchaseClicked}
          trigger={<Button disabled={purchaseButtonDisabled}>{purchaseText}</Button>}
          buttonCopy={purchaseText}
          error={error}
        />
        <Button disabled={noThanksButtonDisabled} onClick={onNoThanks} secondary>
          No, thanks
        </Button>
      </div>
      <div className={styles.terms}>
        By {action}, you agree to our{' '}
        <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
          {' '}
          terms and conditions
        </a>
        .
      </div>
      {error && <ErrorMessage errors={[error]} />}
    </div>
  );
}
