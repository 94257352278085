import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useHistory } from 'react-router-dom';

import AppPaths from '../../../../AppPaths';
import { BillingSubscription, SubscriptionOption } from '../SplashPage';
import Button from '../../../../components/Button/Button';
import { centsToDollars, centsToDollarsNumber, expectUnreachable } from '../../../../lib/util';
import pillStyles from '../styles/SupplementPill.module.scss';
import purchasablePillStyles from '../../../Checkout/components/Supplements/ProductSelector/ProductSelector.module.scss';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import { WeightRange } from '../../../../types/gql-op-types';
import { ReactComponent as DownArrow } from '../../../../assets/images/icons/arrow_down.svg';

export function getRecommendedText(recommendedForDogWeightRangePounds: WeightRange) {
  let text = 'Recommended for dogs ';
  const { min, max } = recommendedForDogWeightRangePounds ?? { min: null, max: null };
  if (min && max) {
    text += `${min}-${max}lbs`;
  } else if (!min) {
    text += `up to ${max}lbs`;
  } else if (!max) {
    text += `over ${min}lbs`;
  }
  return text;
}

interface InformationSupplementPillProps {
  hasMultipleSubscriptions: boolean;
  subscription: BillingSubscription;
  pendingPlan?: SubscriptionOption;
}

function InformationSupplementPill({
  hasMultipleSubscriptions,
  subscription,
  pendingPlan,
}: InformationSupplementPillProps) {
  const history = useHistory();
  const { supplementShipmentDates, nextSupplementShipmentDetails } = subscription;
  if (!supplementShipmentDates) {
    throw new Error('Missing shipment dates. Please contact support@tryfi.com.');
  }
  const { weeksBetweenShipments, priceInCents } = subscription.subscriptionOption;

  const shipmentStatus = nextSupplementShipmentDetails?.status;
  const expectedDeliveryDate = nextSupplementShipmentDetails?.deliveryExpected;
  const trackingLink = nextSupplementShipmentDetails?.trackingLink;

  const handleManageClick = () => {
    events.manageSubscription({ subscriptionId: subscription.id });
    history.push({
      pathname: AppPaths.Supplements.Manage(subscription.id),
      state: { hasMultipleSubscriptions, subscription, pendingSubscription: pendingPlan },
      search: window.location.search,
    });
  };

  const shippingCadenceText = () => {
    // If there are pending changes (meaning the user has updated this subscription previously), we need to get more
    // information about the future plan so we can display it to the user. Behind the scenes, in Recurly, their plan will only be updated
    // on their next bill date (to avoid shipping a new bag of supplements prematurely), but we make an immediate change to the next bill
    // date, which appears in the UI, so it should appear as though the plan change has also happened immediately. To do this,
    // we'll get the plan information of the new plan and display it here.
    let shippingCadence = weeksBetweenShipments;
    if (pendingPlan) {
      shippingCadence = pendingPlan.weeksBetweenShipments;
    }

    return `Ships every ${shippingCadence} weeks`;
  };

  const nextShipmentText = () => {
    return `${DateTime.fromISO(supplementShipmentDates.displayDate).toFormat('cccc, LLL d')}`;
  };

  const expectedDeliveryText = () => {
    if (!expectedDeliveryDate) {
      return `Edit by ${DateTime.fromISO(supplementShipmentDates.displayDate)
        .minus({ minutes: 1 })
        .toFormat('h:mm a ZZZZ')} on ${DateTime.fromISO(supplementShipmentDates.displayDate)
        .minus({ minutes: 1 })
        .toFormat('cccc, LLL d')}`;
    }
    return `Delivery expected ${DateTime.fromISO(expectedDeliveryDate).toFormat('L/d')}`;
  };

  return (
    <div className={classNames(pillStyles.supplementPillContainer, pillStyles.informational)}>
      <div className={pillStyles.row}>
        <div className={pillStyles.col}>
          <p className={pillStyles.status}>{shipmentStatus}</p>
          <h2>{nextShipmentText()}</h2>
        </div>
        <Button secondary onClick={handleManageClick}>
          Manage
        </Button>
      </div>
      {expectedDeliveryText && (
        <p className={pillStyles.deliveryExpected}>
          {expectedDeliveryText()}
          {trackingLink && (
            <span>
              {' '}
              |{' '}
              <a className={pillStyles.trackingLink} href={trackingLink} target="_blank" rel="noreferrer">
                Track Shipment
              </a>
            </span>
          )}
        </p>
      )}

      <div className={pillStyles.divider}></div>

      <div className={pillStyles.shipping}>{shippingCadenceText()}</div>
      <div className={pillStyles.updatedBilledAs}>
        Billed as <span className={pillStyles.emphasize}>${centsToDollarsNumber(priceInCents)}/</span>shipment
      </div>
    </div>
  );
}

interface SupplementPillUpdateOptionProps {
  subscriptionOption: SubscriptionOption;
  handleClick: () => void;
  selected: boolean;
}
export function SupplementPillUpdateOption({
  subscriptionOption,
  handleClick,
  selected,
}: SupplementPillUpdateOptionProps) {
  const { sku, recommendedForDogWeightRangePounds, weeksBetweenShipments } = subscriptionOption;
  if (!sku) {
    throw new Error('No sku found');
  }

  return (
    <>
      <div
        className={classNames(pillStyles.supplementPillContainer, pillStyles.selectable, {
          [pillStyles.selected]: selected,
        })}
        onClick={handleClick}
      >
        <div className={pillStyles.shipping}>Ships every {weeksBetweenShipments} weeks</div>
        {recommendedForDogWeightRangePounds && (
          <div className={pillStyles.recommended}>{getRecommendedText(recommendedForDogWeightRangePounds)}</div>
        )}
      </div>
    </>
  );
}

interface SupplementPillPurchaseOptionProps {
  subscriptionOption: SubscriptionOption;
  handleClick?: () => void;
  selected: boolean;
  showDownArrow?: boolean;
}
export function SupplementPillPurchaseOption({
  subscriptionOption,
  handleClick,
  selected,
  showDownArrow = false,
}: SupplementPillPurchaseOptionProps) {
  return (
    <div
      className={classNames(pillStyles.purchasableOption, purchasablePillStyles.supplementProductSelector, {
        [pillStyles.selected]: selected,
      })}
      onClick={handleClick}
    >
      <div className={purchasablePillStyles.top}>
        <div className={purchasablePillStyles.left}>
          <div className={purchasablePillStyles.size}>{subscriptionOption.name}</div>
          <div className={purchasablePillStyles.shippingCadence}>
            Ships every {subscriptionOption.weeksBetweenShipments} {subscriptionOption.displayedRateUnit}s
          </div>
          {subscriptionOption.recommendedForDogWeightRangePounds && (
            <div className={purchasablePillStyles.recommended}>
              {' '}
              {getRecommendedText(subscriptionOption.recommendedForDogWeightRangePounds)}
            </div>
          )}
        </div>
        <div className={purchasablePillStyles.right}>
          <div className={purchasablePillStyles.price}>
            <strong>
              {subscriptionOption.displayedRate}/{subscriptionOption.displayedRateUnit}
            </strong>
          </div>
          <DownArrow className={classNames({ [pillStyles.hideDown]: !showDownArrow })} />
        </div>
      </div>
      <div className={purchasablePillStyles.bottom}>
        <div className={purchasablePillStyles.divider} />
        <span className={purchasablePillStyles.billingCadence}>
          Billed as ${centsToDollars(subscriptionOption.priceInCents)}{' '}
          <span className={purchasablePillStyles.originalPrice}>
            ${centsToDollars(subscriptionOption.discountedFromOriginalPriceInCents!)}{' '}
          </span>
          /shipment.
        </span>
      </div>
    </div>
  );
}

type SupplementPillVersion =
  | 'informational' // Display information about a user's existing plan.
  | 'selectable'; // Display a selectable subscription pill for user to select when changing plan.

interface CommonSupplementPillProps {
  version: SupplementPillVersion;
  paused?: boolean;
  handleClick?: () => void;
  selected?: boolean;
  pendingPlan?: SubscriptionOption;
  hasMultipleSubscriptions?: boolean;
}

interface InfoPillProps {
  subscription: BillingSubscription;
  subscriptionDetails: null;
}

interface UpdatePillProps {
  subscription: BillingSubscription;
  subscriptionDetails: SubscriptionOption;
}

type ConditionalSupplementPillProps = InfoPillProps | UpdatePillProps;

export default function SupplementPill({
  hasMultipleSubscriptions = false,
  subscription,
  subscriptionDetails,
  version,
  handleClick,
  selected = false,
  pendingPlan,
}: CommonSupplementPillProps & ConditionalSupplementPillProps) {
  const subscriptionOption = subscriptionDetails ? subscriptionDetails : subscription.subscriptionOption;
  switch (version) {
    case 'informational':
      return (
        subscription && (
          <InformationSupplementPill
            hasMultipleSubscriptions={hasMultipleSubscriptions}
            subscription={subscription}
            pendingPlan={pendingPlan}
          />
        )
      );
    case 'selectable':
      return (
        subscription && (
          <SupplementPillUpdateOption
            subscriptionOption={subscriptionOption}
            selected={selected}
            handleClick={() => handleClick && handleClick()}
          />
        )
      );

    default:
      expectUnreachable(version);
      throw new Error(`Unknown supplement pill version: ${version}`);
  }
}
