import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import AppPaths from '../../../AppPaths';
import { BillingSubscription } from './SplashPage';
import Button from '../../../components/Button';
import ErrorMessage, { useError } from '../../../components/ErrorMessage';
import { getLoadingOrErrorElement } from '../../../lib/util';
import { ISupplementSubscriptionProduct } from '../../../types';
import Loading from '../../../components/Loading';
import { logInternalError } from '../../../lib/errors';
import { ReactComponent as DownArrow } from '../../../assets/images/icons/arrow_down.svg';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import * as types from '../../../types';
import UpdateCadenceModal from './components/UpdateCadenceModal';
import { updateSubscriptionMutation, supplementsSubscriptionsQuery } from '../../../graphql-operations';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

interface ChangeFrequencyDeterrentState {
  subscription: BillingSubscription;
  currentSubscriptionOption: ISupplementSubscriptionProduct;
  subscriptionOptions: ISupplementSubscriptionProduct[];
}

export default function ChangeFrequencyDeterrent() {
  const pageName = 'Change Frequency Deterrent';
  const history = useHistory();
  const location = useLocation<ChangeFrequencyDeterrentState>();
  const { subscription, currentSubscriptionOption, subscriptionOptions } = location.state;
  const { error, errorID, setError } = useError();
  const showDiscountDeterrent = useSelector(
    (state: types.AppState) => state.config.siteConfig.showDiscountDeterrent ?? false,
  );

  // Only possible options are less frequent than current plan.
  const frequencyOptions = subscriptionOptions
    .filter((option) => option.weeksBetweenShipments > currentSubscriptionOption.weeksBetweenShipments)
    .sort((a, b) => a.weeksBetweenShipments - b.weeksBetweenShipments);
  const showDropdown = frequencyOptions.length > 1;

  // Default to most frequent of less frequent options.
  const defaultSubscriptionProduct = frequencyOptions[0];
  const defaultSku = defaultSubscriptionProduct.sku;
  const [selectedSku, setSelectedSku] = useState(defaultSku);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSupplementSubscriptionProduct, setSelectedSupplementSubscriptionProduct] =
    useState(defaultSubscriptionProduct);
  events.pageLoad({ subscriptionId: subscription.id }, pageName);

  const shippingText = () => {
    const selectedOption = subscriptionOptions.find((option) => option.sku === selectedSku);
    return `Ships every ${selectedOption?.weeksBetweenShipments} weeks`;
  };

  const [updateMutation, updateMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_updateSupplementSubscription,
    types.gqlTypes.ECOMMERCE_updateSupplementSubscriptionVariables
  >(updateSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.updateSupplementSubscription?.success;
      if (success) {
        history.push({
          pathname: AppPaths.Supplements.Root,
          state: { toast: { success: true, change: 'updated' }, refetch: false },
          search: window.location.search,
        });
        events.shipmentFrequencyUpdated({
          subscriptionId: subscription.id,
          oldSku: currentSubscriptionOption.sku,
          newSku: selectedSku,
        });
      }
      setError(`Failed to update subscription: ${data.updateSupplementSubscription?.reasonForFailure}`);
    },
    onError(err) {
      setError(err.message);
      logInternalError(err);
    },
  });

  const updateLoading = getLoadingOrErrorElement(updateMutationState.loading, null);

  const handleSelection = (sku: string) => {
    setModalOpen(false);
    setSelectedSku(sku);
    const selectedOption = subscriptionOptions.find((option) => option.sku === selectedSku);
    setSelectedSupplementSubscriptionProduct(selectedOption!);
  };

  const handleUpdate = () => {
    events.changeFrequencyDeterrentAccepted({ subscriptionId: subscription.id, newSku: selectedSku });
    updateMutation({
      variables: {
        input: {
          newSku: selectedSku,
          recurlySubscriptionId: subscription.id,
        },
      },
    });
  };

  const handleCancel = () => {
    events.changeFrequencyDeterrentDeclined({ subscriptionId: subscription.id });
    if (showDiscountDeterrent) {
      history.push({
        pathname: AppPaths.Supplements.Discount(subscription.id),
        state: { subscription },
        search: window.location.search,
      });
    } else {
      history.push({
        pathname: AppPaths.Supplements.Survey(subscription.id),
        state: { subscription },
        search: window.location.search,
      });
    }
  };

  const previewNextShipDateText = () => {
    const preview = subscription.supplementShipmentDates!.previewUpdate.find(
      (previewDate) => previewDate.sku === selectedSku,
    );
    if (!preview) {
      return '';
    }

    return (
      <>
        After updating, the date of your next shipment will be{' '}
        <span className={styles.emphasize}>{DateTime.fromISO(preview.nextShipDate).toFormat('cccc, LLL d')}</span>.
      </>
    );
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.goBackFromPage({ subscriptionId: subscription.id }, pageName)}
          closeButtonAnalyticsEvent={() => events.closePage({ subscriptionId: subscription.id }, pageName)}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.largeHeader}>
            <h1>Update shipment frequency</h1>
          </div>

          {updateLoading ? (
            <Loading className={styles.loading} />
          ) : (
            <div className={styles.content}>
              <p>
                We’re happy to change how often you receive your supplement shipments. You're currently receiving them
                every {currentSubscriptionOption.weeksBetweenShipments} weeks.
              </p>

              {showDropdown ? (
                <UpdateCadenceModal
                  trigger={
                    <div className={classNames(styles.highlight, styles.rows)}>
                      <div>
                        <div className={styles.header}>Update shipment frequency</div>
                        <div className={styles.title}>{shippingText()}</div>
                      </div>
                      <div>
                        <DownArrow />
                      </div>
                    </div>
                  }
                  subscription={subscription}
                  currentSubscriptionOption={selectedSupplementSubscriptionProduct}
                  subscriptionOptions={frequencyOptions}
                  onContinue={(sku) => {
                    setModalOpen(true);
                    handleSelection(sku);
                  }}
                  showCancel={false}
                  open={modalOpen}
                />
              ) : (
                <div className={styles.highlight}>
                  <div className={styles.header}>Update shipment frequency</div>
                  <div className={styles.title}>{shippingText()}</div>
                </div>
              )}
              <p>{previewNextShipDateText()}</p>
            </div>
          )}
          {error && <ErrorMessage errors={[error]} errorID={errorID} />}
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={handleUpdate}>
            Update shipment frequency
          </Button>
          <Button className={styles.button} onClick={handleCancel} tertiary>
            Continue with Cancellation
          </Button>
        </div>
      </div>
    </>
  );
}
