import classNames from 'classnames';
import React from 'react';
import styles from './BuyOneBagContent.module.scss';
import { ISupplementProduct } from '../../../../../../types';
import { centsToDollars } from '../../../../../../lib/util';

interface BuyOneBagContentProps {
  product: ISupplementProduct;
}
export default function BuyOneBagContent({ product }: BuyOneBagContentProps) {
  return (
    <div className={classNames(styles.buyOneBagContainer)}>
      <div className={styles.title}>Buy one bag</div>
      <div className={styles.price}>${centsToDollars(product.priceInCents)}</div>
    </div>
  );
}
