import React from 'react';

import styles from './ProductInfo.module.scss';
import { ReactComponent as IconCheck } from '../../../../../assets/images/icons/check.svg';
import classNames from 'classnames';
import { ISupplementSubscriptionProduct } from '../../../../../types';

interface TermsAndConditionsProps {
  hideFree: boolean;
  selectedProduct: ISupplementSubscriptionProduct;
}

export default function TermsAndConditions({ hideFree, selectedProduct }: TermsAndConditionsProps) {
  return (
    <div className={styles.terms}>
      <div className={styles.term}>
        <IconCheck />
        Saving 15% on every shipment
      </div>
      <div className={styles.term}>
        <IconCheck />
        Free shipping every {selectedProduct.weeksBetweenShipments} weeks
      </div>
      <div className={styles.term}>
        <IconCheck />
        Skip, change frequency, or cancel anytime
      </div>
      <div className={classNames(styles.term, styles.purchaseDisclaimer)}>
        By subscribing, you agree to our{' '}
        <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
          terms and conditions
        </a>
        .
      </div>
    </div>
  );
}
