import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add.svg';
import Button from '../../components/Button';
import CartSummary from '../../components/CartSummary';
import ItemsInCart from '../../components/ItemsInCart';
import { CartPricingProvider } from '../../contexts/CartPricingContext';
import * as events from '../../lib/analytics/events';
import { useCartMode } from '../../lib/cartModes';
import CheckoutContext, { CheckoutContextProvider } from '../../lib/CheckoutContext';
import { priceCartForPreview } from '../../lib/pricing';
import * as types from '../../types';
import styles from './Cart.module.scss';
import MultidogDiscountExistingCollars from '../../components/MultidogDiscount';
import { useSelector } from 'react-redux';
import CartValueProps from '../../components/CartSummary/CartValueProps';
import { showNanoVariant } from '../../components/NanoBanner/NanoBanner';
import { inCartS3CollarCount } from '../../components/ItemsInCart/CouponLineItemV2';
import { getFiGrowthBook } from '../../lib/growthbook';

function useTracking({ cartItems }: types.Cart) {
  const [hasLoggedPageLoad, setHasLoggedPageLoad] = useState(false);
  useEffect(() => {
    if (!hasLoggedPageLoad && Object.keys(cartItems).length > 0) {
      events.cartPage.viewed(cartItems);
      setHasLoggedPageLoad(true);
    }
  }, [cartItems, hasLoggedPageLoad]);
}

function CartContainer() {
  const { cart } = useContext(CheckoutContext);
  const { rootPath } = useCartMode();
  const history = useHistory();
  const cartV2Enabled = showNanoVariant() || getFiGrowthBook().getFeatureValue('nano-ecom-test-1', false);
  const isCartEmpty = Object.keys(cart.cartItems).length <= 0;
  const hasS3CollarInCart = inCartS3CollarCount(Object.values(cart.cartItems)) > 0;
  const multiDogDiscountAmount = useSelector((state: types.AppState) => state.config.siteConfig.multiDogDiscountAmount);
  const multiDogHouseholdDiscountEnabled = useSelector(
    (state: types.AppState) => state.config.siteConfig.multiDogHouseholdDiscountEnabled ?? false,
  );

  useTracking(cart);

  const fetchPricing = useCallback(() => {
    return priceCartForPreview(cart);
  }, [cart]);

  if (isCartEmpty) {
    return <Redirect to={rootPath} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.leftContainer}>
        <h2>Your cart</h2>
      </div>
      <div className={styles.rightContainer}>
        <CartPricingProvider fetchPricing={fetchPricing}>
          <div className={styles.cartItemsContainer}>
            <div className={styles.cartItems}>
              <ItemsInCart allowRemoveCartItem allowRemoveCode />
            </div>
          </div>
          {cartV2Enabled && <CartValueProps />}
          {hasS3CollarInCart && (
            <div className={styles.cartItemsContainer}>
              <div className={styles.addItemsButtonContainer}>
                <Button
                  className={styles.addItemsButton}
                  secondary
                  onClick={() => {
                    history.push(AppPaths.Home);
                  }}
                >
                  <AddIcon />
                  Add another collar
                </Button>
                {multiDogHouseholdDiscountEnabled && !!multiDogDiscountAmount && (
                  <>
                    <div className={styles.multidogDiscountBanner}>
                      <div className={styles.multidogDiscountBannerBoldText}>Have multiple dogs?</div>
                      <div>Get {multiDogDiscountAmount}% off all Fi prepaid memberships & no activation fee.</div>
                    </div>
                    <MultidogDiscountExistingCollars />
                  </>
                )}
              </div>
            </div>
          )}
          <CartSummary
            onCheckOut={(checkoutCart, cartPricing) => {
              events.cartPage.checkout(checkoutCart.cartItems, cartPricing);
            }}
          />
        </CartPricingProvider>
      </div>
    </div>
  );
}

export default function Cart() {
  // Cart needs to be wrapped with checkout context to handle Apple Pay, which is a one-click checkout from the cart
  return (
    <CheckoutContextProvider>
      <CartContainer />
    </CheckoutContextProvider>
  );
}
