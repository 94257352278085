import React from 'react';
import { ReactComponent as IconCompose } from '../../../../../assets/images/icons/supplements_management/icon_compose.svg';
import { ReactComponent as IconSavings } from '../../../../../assets/images/icons/supplements_management/icon_savings.svg';
import { ReactComponent as IconTruck } from '../../../../../assets/images/icons/supplements_management/icon_truck_filled.svg';
import valuePropsStyles from '../../../../ProductDetails/ProductDetails.module.scss';
import { ValueProp } from '../../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import { ISupplementSubscriptionProduct } from '../../../../../types';

export const SUBSCRIBE_AND_SAVE_DETAILS = (subscriptionOption: ISupplementSubscriptionProduct): ValueProp[] => {
  return [
    {
      icon: <IconSavings />,
      text: (
        <div className={valuePropsStyles.valueProp}>
          <span className={valuePropsStyles.valuePropHeader}>Keep saving 15% on every delivery</span>
        </div>
      ),
    },
    {
      icon: <IconTruck />,
      text: (
        <div className={valuePropsStyles.valueProp}>
          <span className={valuePropsStyles.valuePropHeader}>
            Ships every {subscriptionOption.weeksBetweenShipments} weeks&nbsp;&nbsp;|&nbsp;&nbsp;Free shipping
          </span>
        </div>
      ),
      fade: true,
    },
    {
      icon: <IconCompose />,
      text: <div className={valuePropsStyles.valueProp}>Skip, change frequency, or cancel anytime</div>,
      fade: true,
    },
  ];
};

export const BUY_ONE_BAG_DETAILS: ValueProp[] = [
  {
    icon: <IconTruck />,
    text: (
      <div className={valuePropsStyles.valueProp}>
        <span className={valuePropsStyles.valuePropHeader}>Ships once&nbsp;&nbsp;|&nbsp;&nbsp;Free shipping</span>
      </div>
    ),
    fade: true,
  },
];
