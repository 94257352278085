import { CarouselController } from '../../../../../components/Carousel';
import classNames from 'classnames';
import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import React from 'react';
import styles from './ImageGallery.module.scss';
import * as types from '../../../../../types';

const DESKTOP_IMAGE_GALLERY_SIZE = 4;

const PRODUCT_IMAGES: types.IImage[] = [
  {
    type: 'image',
    url: '/product_images/supplements/subscribe-and-save/bag-front.png',
    retinaWidth: 1200,
    retinaHeight: 1200,
  },
  {
    type: 'image',
    url: '/product_images/supplements/subscribe-and-save/lifestyle.png',
    retinaWidth: 1200,
    retinaHeight: 1200,
  },
  {
    type: 'image',
    url: '/product_images/supplements/subscribe-and-save/ingredients.png',
    retinaWidth: 1200,
    retinaHeight: 1200,
  },
];

const LIFESTYLE_IMAGES: types.IImage[] = [
  {
    type: 'image',
    url: '/product_images/supplements/subscribe-and-save/bag-reach.png',
    retinaWidth: 1200,
    retinaHeight: 1200,
  },
];

interface DesktopImageGalleryProps {
  carouselControllerRef?: React.MutableRefObject<CarouselController | undefined>;
  onChange?: (index: number) => void;
  children?: React.ReactNode;
  media?: types.IImage[];
}

export function DesktopImageGallery({
  carouselControllerRef,
  onChange,
  children,
  media: mediaFromProps,
}: DesktopImageGalleryProps) {
  if (mediaFromProps) {
    return (
      <div className={classNames(styles.imageGalleryContainer, styles.desktopImageGallery)}>
        {children}
        <ImageGallery
          parentCarouselControllerRef={carouselControllerRef}
          media={mediaFromProps}
          pageDots={true}
          disableAnimation={true}
          friction={0.4}
          onChange={onChange}
        />
      </div>
    );
  }

  // Maintain a maximum of 4 images in carousel
  const images = [...PRODUCT_IMAGES, ...LIFESTYLE_IMAGES].slice(0, DESKTOP_IMAGE_GALLERY_SIZE);

  const media: types.IImage[] = images;

  return (
    <div className={classNames(styles.imageGalleryContainer, styles.desktopImageGallery)}>
      {children}
      <ImageGallery
        parentCarouselControllerRef={carouselControllerRef}
        media={media}
        pageDots={true}
        disableAnimation={true}
        friction={0.4}
        onChange={onChange}
      />
    </div>
  );
}
