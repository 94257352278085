import React from 'react';
import { centsToDollars, centsToDollarsNumber } from '../../../../../../lib/util';
import styles from './SubscribeAndSaveContent.module.scss';
import classNames from 'classnames';
import { ISupplementSubscriptionProduct } from '../../../../../../types';

interface SubscribeAndSaveContentProps {
  subscriptionOption: ISupplementSubscriptionProduct;
  freeTrial: boolean;
}

export default function SubscribeAndSaveContent({ subscriptionOption, freeTrial }: SubscribeAndSaveContentProps) {
  const ORIGINAL_PRICE_IN_CENTS_DEFAULT = 3500;

  return (
    <div className={styles.subscribeAndSaveContainer}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.titleRow)}>
          <div className={styles.title}>Subscribe & Save</div>
          <div className={styles.save}>{subscriptionOption.savings}</div>
        </div>
        <div className={classNames(styles.row, styles.priceRow)}>
          <span className={styles.price}>${freeTrial ? '0.00' : centsToDollars(subscriptionOption.priceInCents)}</span>
          <span className={classNames(styles.originalPrice, styles.strikethrough)}>
            ${centsToDollars(subscriptionOption.originalPriceInCents ?? ORIGINAL_PRICE_IN_CENTS_DEFAULT)}
          </span>
        </div>
        {freeTrial ? (
          <div className={styles.description}>
            <span className={styles.bold}>1st bag FREE</span> <span className={styles.thin}>|</span>{' '}
            <span className={styles.bold}>${centsToDollarsNumber(subscriptionOption.priceInCents)}</span>{' '}
            <span className={styles.strikethrough}>
              ${centsToDollarsNumber(subscriptionOption.originalPriceInCents ?? ORIGINAL_PRICE_IN_CENTS_DEFAULT)}
            </span>{' '}
            all other deliveries
          </div>
        ) : (
          <div className={styles.description}>
            Billed per shipment, every {subscriptionOption.weeksBetweenShipments} weeks
          </div>
        )}
      </div>
    </div>
  );
}
