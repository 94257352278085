import React from 'react';
import { ReactComponent as IconKit } from '../../../../../assets/images/icons/icon_kit.svg';
import { ReactComponent as IconCalendar } from '../../../../../assets/images/icons/icon_calendar.svg';
import { ReactComponent as IconWarranty } from '../../../../../assets/images/icons/icon_warranty.svg';
import styles from './NanoValueProps.module.scss';
import ValuePropsBox from '../ValuePropsBox';

export default function NanoValueProps() {
  return (
    <div className={styles.valuePropsSection}>
      <ValuePropsBox
        className="nano-value-props"
        valueProps={[
          {
            icon: <IconKit />,
            text: (
              <div className={styles.valueProp}>
                <span className={styles.valuePropHeader}>Fi Series 3 device + collar included for free</span>
              </div>
            ),
          },
          {
            icon: <IconCalendar />,
            text: (
              <div className={styles.valueProp}>
                <span className={styles.valuePropHeader}>30 day risk free trial</span>
              </div>
            ),
          },
          {
            icon: <IconWarranty />,
            text: (
              <div className={styles.valueProp}>
                <div className={styles.valuePropHeader}>Lifetime warranty</div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
