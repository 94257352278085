import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import Chooser from '../../../../components/Chooser';
import { SimpleDismissableModal } from '../../../../components/modals';
import { variantWithOptions } from '../../../../lib/product';
import { f1BandSizeOptions, f3BandSizeOptions } from '../../../../lib/size';
import { expectUnreachable } from '../../../../lib/util';
import * as types from '../../../../types';
import SizeOptionDetails from '../SizeOptionDetails';
import styles from './SizeOptions.module.scss';
import { useSelector } from 'react-redux';
import * as events from '../../../../lib/analytics/events';
import DynamicSizingGuide from '../SizingGuide/DynamicSizingGuide';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import CustomEase from 'gsap/CustomEase';
import {
  getNanoVariantPetAgeMonths,
  getNanoVariantPetName,
  showNanoVariant,
} from '../../../../components/NanoBanner/NanoBanner';
import { getFiGrowthBook } from '../../../../lib/growthbook';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
CustomEase.create('scrollTo', '0.65, 0, 0.35, 1');

interface ISizeOptionsProps {
  bandSeries: types.BandSeries;
  needsSelection?: boolean;
  onOptionChange: (changed: Partial<types.IVariantOptions>) => void;
  selectedVariant?: types.IVariant;
  title?: string;
  variants: types.IVariant[];
  startAsUnselected: boolean;
}

const SizeOptions = ({
  bandSeries,
  needsSelection,
  onOptionChange,
  selectedVariant,
  title = 'Choose the size',
  variants,
  startAsUnselected,
}: ISizeOptionsProps) => {
  const makersBandSelected = selectedVariant?.bandMaker !== types.BandMaker.Fi;
  const blockedSKUs = useSelector((state: types.AppState) => state.config.siteConfig.blockedOutOfStockSkus || []);
  const isSizeAvailable = useCallback(
    (size: types.SizeOption) => {
      const thisOption = {
        ...selectedVariant?.options,
        size,
      };

      const variantForSize = variantWithOptions(variants, thisOption);
      return variantForSize && !(blockedSKUs ?? []).includes(variantForSize.sku);
    },
    [selectedVariant?.options, variants, blockedSKUs],
  );

  const allSizesWithDetails = useMemo(() => {
    const sizeSet = new Set<types.SizeOption>();
    variants.forEach((v) => sizeSet.add(v.options.size));

    if (bandSeries === types.BandSeries.F1) {
      return f1BandSizeOptions
        .filter((s) => sizeSet.has(s))
        .map((s) => ({
          size: s,
          isAvailable: isSizeAvailable(s),
        }));
    } else if (bandSeries === types.BandSeries.F3) {
      return f3BandSizeOptions
        .filter((s) => sizeSet.has(s))
        .map((s) => ({
          size: s,
          isAvailable: isSizeAvailable(s),
        }));
    } else {
      expectUnreachable(bandSeries);
      throw new Error(`Unsupported band series: ${bandSeries}`);
    }
  }, [bandSeries, isSizeAvailable, variants]);

  const sizeOptionRefs = allSizesWithDetails.reduce((acc, { size }) => {
    acc[size] = React.createRef();
    return acc;
  }, {} as Record<string, React.RefObject<HTMLDivElement>>);
  const scrollToSizeOption = (size: types.SizeOption) => {
    const element = sizeOptionRefs[size]?.current;
    if (!element) return;

    const viewportHeight = window.innerHeight;
    const rect = element.getBoundingClientRect();
    const offsetY = viewportHeight / 2 - rect.height / 2;

    gsap.to(window, {
      duration: 1.2,
      scrollTo: { y: element, offsetY: offsetY },
      ease: 'scrollTo',
    });
  };

  const [showSelected, setShowSelected] = useState<boolean>(!startAsUnselected);
  const sizeChooser = useMemo(() => {
    return (
      <Chooser
        onSelect={(size: types.SizeOption) => {
          setShowSelected(true);
          onOptionChange({ size });
        }}
        selectedOption={showSelected ? selectedVariant?.options.size : undefined}
        groups={[
          {
            options: allSizesWithDetails.map(({ size, isAvailable }) => ({
              content: (
                <SizeOptionDetails
                  sizeOptionRef={sizeOptionRefs[size]}
                  sizeOption={size}
                  bandSeries={bandSeries}
                  bandMaker={selectedVariant?.bandMaker}
                  color={selectedVariant?.options.color}
                />
              ),
              disabled: !isAvailable,
              value: size,
            })),
          },
        ]}
      />
    );
  }, [allSizesWithDetails, onOptionChange, bandSeries, selectedVariant, showSelected, sizeOptionRefs]);
  const sizingGuide = (
    <>
      {' '}
      {!makersBandSelected && (
        <div className={styles.moreInfo}>
          <SimpleDismissableModal
            onOpen={() => events.series3CollarWhatSizeIsRight()}
            trigger={
              <button className={styles.sizingGuideButton}>
                What size is right?
                <div className={styles.buttonArrowAngle} />
              </button>
            }
          >
            <DynamicSizingGuide
              bandSeries={bandSeries}
              onSelect={(size: types.SizeOption) => {
                setShowSelected(true);
                onOptionChange({ size });
                scrollToSizeOption(size);
              }}
            />
          </SimpleDismissableModal>
        </div>
      )}
    </>
  );
  const nanoVariantEnabled = showNanoVariant();
  const petName = getNanoVariantPetName();
  const petAge = getNanoVariantPetAgeMonths();
  const nanoSizeSuggestionEnabled =
    nanoVariantEnabled && getFiGrowthBook().getFeatureValue('nano-registration-size-suggestion-experiment', false);
  const subtitleText =
    petName && petAge <= 6
      ? `When ${petName} is fully grown, we’ll swap it out for a larger size, for free.`
      : nanoSizeSuggestionEnabled
      ? `Based on ${petName}’s breed and weight.`
      : null;

  return (
    <>
      <div className={classNames(styles.sizeVariant, 'size-options')}>
        <div className={styles.variantTitleGroup}>
          <div className={classNames(styles.variantTitle, needsSelection ? styles.variantTitleError : undefined)}>
            {title}
            {nanoVariantEnabled && subtitleText && <div className={styles.nanoSizeDescription}>{subtitleText}</div>}
            {!nanoSizeSuggestionEnabled && sizingGuide}
          </div>
        </div>

        {sizeChooser}
        {nanoSizeSuggestionEnabled && sizingGuide}
      </div>
    </>
  );
};

export default SizeOptions;
